<template>
  <NrButton
    @click="openModal"
    class="update-poc-button"
    variant="filled-success"
    ><NrIcon name="check" />Go live</NrButton
  >

  <NrModal
    class="deactivate-poc-modal"
    primaryButtonText="Update Point of Contact"
    secondaryButtonText="Cancel"
    title="Deactivated Point of Contact"
    hasSecondaryButton
    :show="showModal"
    @on-primary-click="navigateToPortalSettings"
    @on-secondary-click="closeModal"
  >
    The current point of contact is a CivicPlus employee and will be deactivated
    when you go live, please choose a new point of contact before going live.
  </NrModal>
</template>
<script setup>
import NrButton from '@nextrequestco/components/src/components/NrButton/nr-button.vue'
import NrIcon from '@nextrequestco/components/src/components/NrIcon/NrIcon.vue'
import NrModal from '@nextrequestco/components/src/components/NrModal/nr-modal.vue'
import API from '../../../vue_shared/api.js'
import { app } from '../../../../packs/alert_deactivated_poc.ts'
import { ref, onMounted } from 'vue'

const showModal = ref(false)
const account = ref({})

const appToasted = () => {
  return app.config.globalProperties.$toasted
}

const navigateToPortalSettings = () => {
  if (account.value.civicplus_poc) {
    window.location = `/admin/accounts/${account.value.id}`
  }
}

const openModal = async () => {
  if (account.value.civicplus_poc) {
    showModal.value = true
  } else {
    const resp = await API.updateAccountState({ state: 'live' })
    if (resp?.status === 200) {
      appToasted().success('Your portal is now live')
      location.reload()
    } else {
      appToasted().error('There was a problem updating your portal')
    }
  }
}

const closeModal = () => {
  showModal.value = false
}

onMounted(async () => {
  const resp = await API.account()
  account.value = resp?.data
})
</script>
<style lang="scss">
@import '@nextrequestco/components/src/styles/tokens/tokens.scss';
.update-poc-button {
  display: flex;
  .nr-icon {
    margin-top: $spacing-4;
    margin-right: $spacing-4;
  }
}

.deactivate-poc-modal {
  .nr-modal__header {
    background-color: $color-red-100;
    .nr-modal__title {
      color: $color-white;
    }
  }
}
</style>
